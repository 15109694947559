import { Injectable } from '@angular/core';
import { Toast } from '@capacitor/toast';
import {
  Platform,
  ToastButton,
  ToastController,
} from '@ionic/angular/standalone';

@Injectable({ providedIn: 'root' })
export class ToastService {
  constructor(private toastCtrl: ToastController, private platform: Platform) {}

  async show(
    text: string,
    duration: 'short' | 'long' = 'short',
    buttons?: ToastButton[]
  ) {
    const hasButtons = buttons && buttons.length > 0;
    if (this.platform.is('capacitor') && !hasButtons) {
      return Toast.show({
        text,
        duration,
      });
    } else {
      const defaultButtons = [
        {
          text: 'Close',
          role: 'cancel',
        },
      ];
      // we use this instead of @ionic/pwa-elements
      // because it doesn't support buttons AND also looks bad
      const toast = await this.toastCtrl.create({
        message: text,
        duration: duration === 'short' ? 2000 : 3500,
        buttons: buttons || defaultButtons,
      });
      return toast.present();
    }
  }

  showError() {
    this.show('Something went wrong');
  }
}
